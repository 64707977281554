.timetable {
    display: flex;
    flex-direction: row;
}

.timetable-cont {
    width: 100%;
    height: 900px;
    display: flex;
    flex-direction: row;
    margin-left:150px;
    margin-right:5px;


}

.timetable-courses {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    /* margin-left:10px; */
    height: 720px;

}

.timetable ul {
    list-style: none;
}
.timetable li {
  padding-top: 5%;
  padding-bottom: 10px;
  border-radius: 0.25rem;
  margin-top: 5%;
  margin-bottom: 5%;
}


.timetable-line{
  margin-top: 3%;
  background-color: #CBCBCB;
  height:2px;
}