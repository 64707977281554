@import '~antd/dist/antd.css';

.account-cont {
    display: flex;
    flex-direction: row;
}

.content-cont {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    width: 100%;
    margin-left: 150px;
}

.coresRemain-cont {
    font-size: 25px;
}