/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* layout */
:root {
  /* 1aac83 */
  --primary: #1a6aac;
  --error: #e7195a;
}
body {
  background: #f1f1f1 !important;
  margin: 0 !important;
  font-family: "Poppins" !important;
}
header {
  background: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  width: 100%;
  z-index:100;
}
header .container {
  max-width:2500px;
  margin: 0 auto;
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}
header a {
  color: #333;
  text-decoration: none;
}
.pages{
  /* max-width: 1400px;
  padding: 20px;
  margin: 0 auto; */
  margin-top: 60px;
}
/* for NTUMoon and Logo alignment */
header .container h1 {
  display: flex;
}
header .container h1 div {
  height: 50px;
  width: 50px;
  margin-right:20px;
}

/* homepage */
.home {
  /* display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 100px; */
  display: flex;
  flex-direction: row;
}

.course-detail {
  padding: 0 10%;
}

/* login/logout form */
label, input {
  display: block;
}
input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}

div.success {
  padding: 10px;
  background: #d1fdd3;
  border: 1px solid #5cb85c;
  color: #5cb85c;
  border-radius: 4px;
  margin: 20px 0;
}

input.error {
  border: 1px solid var(--error);
}

/* navbar */
nav {
  display: flex;
  align-items: center;
}
nav a, nav button {
  margin-left: 10px;
}
nav button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 1em;
}

/* auth forms */
form.signup, form.login {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  margin-top: 30vh;
}

.resetPassword{
  background: none;
  margin: 0px;
  padding-left: '10px';
  border-radius: 0px;
  text-decoration: none;
  color: black;
}

.resetPassword:hover {
  color: rgb(78, 128, 202);
}