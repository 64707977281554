/* course review page */
.course-code {
    color: #1F1D80;
  }
  
.course-code:hover {
color: #1511f1;
}

.course-detail {
  margin-left:100px;
}