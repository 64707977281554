/* course review page */
.course-descript {
  display: flex;
  flex-direction: row;
}

.btn{
  margin: auto;
}

.course-reviews {
  position: absolute;
  left: 110px;
}

.review-form {
  padding: 0 10%;
  margin-bottom: 3%;
}

.review {
  padding: 0 10%;
}

.mod-rating {
  padding: 0 10%;
}

.course-detail {
  padding: 0 10%;
}

.indv-course-code {
  color: #1F1D80;
}

.solid {
  border-top: 3px solid #bbb;
}

.add-to-tt {
  background: transparent;
  border-color: black;
  color: black;
  font-family: "Poppins";
  width : 100%;
  border-radius: 5pt;
}

.add-to-tt:hover {
  border-width: 2pt;
  border-color: #1511f1;
  color: #1511f1;
}

.remove-from-tt {
  background: transparent;
  border-color: black;
  color: black;
  font-family: "Poppins";
  width : auto;
  border-radius: 5pt;
}

.remove-from-tt:hover {
  border-width: 2pt;
  border-color: #f13611;
  color: #f13611;
}

.mod-reviews {
  font-family: 'Poppins';
}

.add-comment-field {
  border-radius: 10pt 0pt 0pt 10pt;
}

.add-rating-field {
  border-radius: 0pt 10pt 10pt 0pt;
}

.indiv-review {
  margin-bottom: 2%;
}

.comment-user {
  color:#1F1D80;
}

.user-rating {
  text-align: center;
}

.prereq {
  font-size: large;
  color:#1F1D80;
}