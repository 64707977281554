
.settings{
    display: flex;
    flex-direction: row;
}

.settings-cont{
    display: flex;
    flex-direction: column;
    padding-left: 7% ;
    padding-right: 7%;
    margin-left:150px;

}

.settings-header{
    display: flex;

}
.settings-title{
    font-weight: 600;
    font-size: 35px;
    margin-top: 5%;

}
.settings-description{
    font-weight: 400;
}
.settings-line{
    margin-top: 3%;
    background-color: #CBCBCB;
    height:2px;
}
.settings-fields{
    font-weight: 400;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;

}
.settings .btn{
    color: white;
    background-color: #1F1D80;
    width:max-content;
    
}
.settings .settings-btn{
    display: flex;
    justify-content: end;
    margin-top: 4%;

}

*{
    -ms-overflow-style: none;
    }
    ::-webkit-scrollbar {
    display: none;
    }